<template>
  <div
    v-if="success"
  >
    <app-heading class="mb-4">
      {{ t('support_requests.store.success.title') }}
    </app-heading>

    <p class="text-sm mb-2">
      {{ t('support_requests.store.success.text') }}
    </p>
  </div>

  <!-- Use v-show instead of v-if, to not lose form values -->
  <div v-show="!success">
    <app-heading class="mb-3">
      {{ t('support_requests.store.heading') }}
    </app-heading>

    <form
      id="support-request-form"
      novalidate
      @submit.prevent="handleSubmit"
    >
      <form-global-errors />

      <fieldset
        :disabled="submitting"
      >
        <support-request-form-fields />

        <form-label
          :label="t('validation.attributes.attached_files')"
          class="block mb-1"
        />

        <images-form-nested
          name="images"
          :resource="resource"
          class="mb-5"
        />
      </fieldset>

      <div class="flex justify-center fixed right-0 left-0 bottom-24">
        <app-button
          feature="confirm"
          :label="t('common.actions.send')"
          type="submit"
          class="w-5/6"
          :disabled="submitting || invalid"
          :loading="submitting"
        />
      </div>
    </form>
  </div>

  <app-card-item
    background-classes="bg-theme-500"
    class="text-white p-3 font-bold text-sm"
  >
    <p>
      {{ t('form.labels.support_request.warning') }}
    </p>
  </app-card-item>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'
import ImagesFormNested from '@app/components/resources/image/ImagesFormNested.vue'
import SupportRequestFormFields from '@app/components/resources/support_request/SupportRequestFormFields.vue'

const { t } = useI18n()

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Has the support request successfully been sent
  success: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const {
  handleSubmit,
  invalid,
} = useForm(props, { emits })
</script>
