<template>
  <div>
    <div class="flex justify-between items-center mb-2">
      <div>
        <div v-if="byRequestOwner">
          <div
            v-if="resource.id === firstMessage.id"
            class="text-xl font-bold"
          >
            {{ t('support_messages.show.your_question') }}
          </div>
          <div
            v-else
            class="font-bold"
          >
            {{ t('support_messages.show.you') }}
          </div>
        </div>
        <div v-if="!byRequestOwner">
          <div class="font-bold">
            {{ userDisplayName }}
          </div>
        </div>
      </div>
      <div class="text-sm truncate">
        {{ formatDate() }}
      </div>
    </div>
    <div class="flex items-center justify-between mt-4">
      <span class="text-sm text-justify break-words">
        {{ resource.attributes.content }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { DateTime } from 'luxon'

import useDatetime from '@shared/hooks/datetime'
import useUser from '@shared/hooks/resources/user'

const { t } = useI18n()

const {
  formatDateShort,
  formatTimeSimple,
} = useDatetime()

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  firstMessage: {
    type: Object,
    default: () => ({}),
  },
})

const {
  displayName: userDisplayName,
} = useUser({ resource: props.resource.relationships.user })

const byRequestOwner = computed(() => (
  props.resource.relationships.user.id === props.resource.relationships.request.attributes.user_id
))

const createdAtDateShort = computed(() => (
  formatDateShort(props.resource.attributes.created_at)
))

const createdAtTime = computed(() => (
  formatTimeSimple(props.resource.attributes.created_at)
))

function formatDate() {
  const today = DateTime.now().toFormat('dd/MM/yyyy')
  if (createdAtDateShort.value === today) {
    return createdAtTime.value
  }
  return createdAtDateShort.value
}
</script>
