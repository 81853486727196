<template>
  <carousel
    :items-to-show="2"
    :wrap-around="previewImages.length > 2"
  >
    <slide
      v-for="(image, index) in previewImages"
      :key="index"
    >
      <img
        :src="image.attributes.urls.xs"
        class="rounded-md"
        @click="handleGalleryOpen(index)"
      >
    </slide>

    <template #addons>
      <navigation
        v-if="displayNavigation"
      />
    </template>
  </carousel>

  <!-- Gallery modal -->
  <app-overlay
    :show="galleryModalDisplayed"
    @clicked="handleGalleryClose"
  >
    <app-modal @closed="handleGalleryClose">
      <!-- Slides -->
      <carousel
        v-if="images.length > 0"
        ref="galleryCarousel"
        :items-to-show="1"
        wrap-around
        :autoplay="autoplayTime"
        :mouse-drag="images.length > 1"
        :touch-drag="images.length > 1"
      >
        <slide
          v-for="(image, i) in images"
          :key="i"
        >
          <a
            :href="image.attributes.urls.original"
            target="_blank"
          >
            <img
              :src="image.attributes.urls.md"
              class="rounded-md max-h-[500px]"
            >
          </a>
        </slide>

        <template #addons>
          <navigation
            v-if="displayNavigation"
          />
        </template>
      </carousel>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import {
  Carousel,
  Slide,
  Navigation,
} from 'vue3-carousel'

import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import useImagesGallery from '@shared/hooks/imagesGallery'

const props = defineProps({
  // Images to display
  images: {
    type: Array,
    default: () => ([]),
  },
})

const {
  handleGalleryOpen,
  handleGalleryClose,
  autoplayTime,
  displayNavigation,
  previewImages,
  galleryCarousel,
  galleryModalDisplayed,
} = useImagesGallery(props)
</script>
