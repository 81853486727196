<template>
  <span
    :class="`bg-${color}-200 text-${color}-600 py-1 px-3 rounded-full text-xs`"
  >
    {{ text }}
  </span>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  // Text to display
  text: {
    type: String,
    required: true,
  },
  // Design of the badge
  // (success, warning, danger...)
  design: {
    type: String,
    default: null,
  },
})

const color = computed(() => {
  switch (props.design) {
    case 'success':
      return 'green'
    case 'danger':
      return 'red'
    case 'warning':
      return 'yellow'
    case 'info':
      return 'purple'
    default:
      return 'gray'
  }
})
</script>
