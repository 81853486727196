<template>
  <form-group
    name="support_category_id"
    rules="required"
    type="select"
    :hint="t('form.hints.support_request.support_categories_search')"
    :form-control-props="{
      options: handleSupportCategoriesSearch,
      filterable: false,
    }"
  >
    <template #control-vue-multiselect-option="option">
      <strong>
        {{ option.title }}
      </strong>
    </template>
  </form-group>

  <form-group
    name="title"
    type="string"
    rules="required"
    :label="t('form.labels.support_request.title')"
  />

  <form-group
    name="content"
    type="textarea"
    rules="required"
    :label="t('form.labels.support_message.content')"
    :form-control-props="{
      rows: 5,
      limitChars: 600,
    }"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useSupportCategories from '@shared/hooks/useSupportCategories'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { t } = useI18n()
const { handleSupportCategoriesSearch } = useSupportCategories()

</script>
