import { computed, nextTick, ref } from 'vue'

export default function useImagesGallery(props) {
  const galleryModalDisplayed = ref(false)
  const galleryCarousel = ref() // carousel template ref

  async function handleGalleryOpen(index) {
    galleryModalDisplayed.value = true

    // Wait for carousel to appears, and ref to be available
    await nextTick()
    selectImage(index)
  }

  // Only use the 4 first images in the preview
  const previewImages = computed(() => (
    props.images.slice(0, 4)
  ))

  const multipleImages = computed(() => (
    props.images.length > 1
  ))

  const displayNavigation = computed(() => (
    multipleImages.value
  ))

  const autoplayTime = computed(() => (
    multipleImages.value ? 5000 : null
  ))

  function handleGalleryClose() {
    galleryModalDisplayed.value = false
  }

  function selectImage(index) {
    galleryCarousel.value?.slideTo(index)
  }

  return {
    handleGalleryOpen,
    handleGalleryClose,
    autoplayTime,
    selectImage,
    galleryCarousel,
    displayNavigation,
    previewImages,
    galleryModalDisplayed,
  }
}
