import { ref } from 'vue'
import { uniqBy } from 'lodash'

import { fetchSupportCategories } from '@shared/http/api'
import useLocale from '@shared/hooks/locale'

export default function useSupportCategories() {
  const { getAttributeTranslation } = useLocale()
  const initialSupportCategorySearch = ref(true)

  // Return support categories options used in select control
  function formatSupportCategoriesOptions(categories) {
    return categories.map((categoryResource) => ({
      label: getAttributeTranslation(categoryResource.attributes.title),
      title: getAttributeTranslation(categoryResource.attributes.title),
      value: categoryResource.id,
    }))
  }

  function handleSupportCategoriesSearch(searchKeywords) {
    return new Promise((resolve) => {
      let options = []

      if (initialSupportCategorySearch.value) {
        initialSupportCategorySearch.value = false
      }

      const params = {
        'search': searchKeywords,
        'extranet': true,
      }

      fetchSupportCategories(params)
        .then((e) => {
          // Get the new options from the response
          options = uniqBy(options.concat(formatSupportCategoriesOptions(e.data.data)), 'value')
        })
        .finally(() => {
          resolve(options)
        })
    })
  }

  return { handleSupportCategoriesSearch }
}
