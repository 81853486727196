<template>
  <div
    :style="`background-image: url(${imageUrl});`"
    class="w-18 h-18 rounded-md shadow-lg shadow-theme-500/10 bg-cover bg-center"
  />

  <form-group
    :name="nameToUse('id')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('_destroy')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('file')"
    :rules="fileRules"
    type="hidden"
  />

  <input
    ref="fileInput"
    type="file"
    class="hidden"
    :accept="imageMimeTypes"
    @change="handleFileUpload"
  >

  <form-group
    :name="nameToUse('position')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('collection_name')"
    initial-value="images"
    type="hidden"
  />

  <form-error-messages
    :name="nameToUse('file')"
    :data-form-errors="true"
    :error-messages="fileErrors.flat()"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { get } from 'lodash'
import { useField } from 'vee-validate'

import FormGroup from '@shared/components/form/FormGroup.vue'
import FormErrorMessages from '@shared/components/form/FormErrorMessages.vue'
import useFormFields from '@shared/hooks/form/formFields'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Prefix to use in input name,
  // e.g.: if "media[0]" is prefix,
  // title input name will be "media[0].title"
  // to avoid conflicts with other form's title input
  namePrefix: {
    type: String,
    default: null,
  },
})

const {
  markedForDestruction,
  nameToUse,
  form,
} = useFormFields(props)

const fileInput = ref() // file input template ref

const {
  errors: fileErrors,
} = useField(nameToUse('file'))

const imageMimeTypes = import.meta.env.VITE_VALID_IMAGE_MIMETYPES
const imageMaxSize = import.meta.env.VITE_VALID_MAX_FILE_SIZE_KB
const imageMaxWidth = import.meta.env.VITE_VALID_MAX_IMAGE_WIDTH
const imageMaxHeight = import.meta.env.VITE_VALID_MAX_IMAGE_HEIGHT

const fileRules = [
  `mimes:${imageMimeTypes}`,
  `size.file:${imageMaxSize}`,
  `dimensions:max_width=${imageMaxWidth},max_height=${imageMaxHeight}`,
].join('|')

const currentFile = computed(() => (
  get(form.value?.values, nameToUse('file'))
))

const imageUrl = computed(() => {
  // no picture url if it is marked for destruction
  if (!markedForDestruction.value) {
    // uploaded picture
    if (currentFile.value) {
      return URL.createObjectURL(currentFile.value)
    }

    // resource current picture
    return props.resource?.attributes?.urls?.xs
  }

  return null
})

function handleFileUpload(e) {
  // Check if a file is present,
  // useful when upload is cancel
  const targetFile = e.target.files?.[0]
  if (targetFile) {
    // assign new picture value
    form.value?.setFieldValue(nameToUse('file'), targetFile)
    form.value?.setFieldValue(nameToUse('_destroy'), false)
  }
}
</script>
