import { computed, inject, ref } from 'vue'
import { useFieldArray } from 'vee-validate'
import { get } from 'lodash'

// Use when a component's part of a form
// and include nested forms
export default function useFormNested(props) {
  const form = inject('form', ref({}))
  const {
    push,
  } = useFieldArray(props.name)

  /**
   * Retrieve the json api resource used
   * to populate the nested form fields
   *
   * @param {object} formFields
   * @return {object|null}
   */
  function nestedResource(formFields) {
    return props.resource?.relationships?.[props.name]?.find((relation) => (
      relation.id === formFields?.id
    )) || null
  }

  /**
   * Handle a nested form field sort
   */
  function handleSort() {
    // Adapt the position attribute
    fieldsValues.value.forEach((item, index) => {
      updateNestedFieldsValue(index, { position: index })
    })
  }

  /**
   * Handle a nested form field addition
   */
  function handleFormAdd(callback = () => {}) {
    push({}) // Add new nested form fields

    // Calc added item's index
    const newIndex = fieldsValues.value.length - 1

    // Execute provided callback
    callback(newIndex)
  }

  /**
   * Handle a nested form field removal
   *
   * @param {number} index
   */
  function handleFormRemove(index) {
    // Empty all fields, except "_destroy"
    const newValue = {
      _destroy: true,
    }

    const oldValue = getNestedFieldsValue(index)

    if (oldValue?.id) {
      // Keep "id" if resource already exists server-side
      newValue.id = oldValue.id
    }

    // Apply new value
    form.value.setFieldValue(`${props.name}.${index}`, newValue)
  }

  /**
   * Mark a nested form fields for destruction
   *
   * @param {number} index
   */
  function markForDestruction(index) {
    updateNestedFieldsValue(index, { _destroy: true })
  }

  // Retrieve all fields' values
  const fieldsValues = computed(() => (
    get(form.value.values, props.name) ?? []
  ))

  // Ignore values marked for destruction
  const nonDestroyedValues = computed(() => (
    fieldsValues.value.filter((field) => (
      !field._destroy
    ))
  ))

  /**
   * Retrieve a nested form field value from its index
   *
   * @param {number} index
   * @returns {object}
   */
  function getNestedFieldsValue(index) {
    return fieldsValues.value[index]
  }

  /**
   * Update a nested form field value
   *
   * @param {number} index
   * @param {object} updatedAttributes
   * @returns {object}
   */
  function updateNestedFieldsValue(index, updatedAttributes = {}) {
    Object.entries(updatedAttributes).forEach(([key, value]) => {
      form.value.setFieldValue(`${props.name}.${index}.${key}`, value)
    })
  }

  return {
    fieldsValues,
    nonDestroyedValues,
    nestedResource,
    updateNestedFieldsValue,
    markForDestruction,
    handleSort,
    handleFormAdd,
    handleFormRemove,
  }
}
